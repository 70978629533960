<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <!-- <div class="section-title">
        <h2>Contact</h2>
        <p>
          Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
          aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
          quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
          fugiat sit in iste officiis commodi quidem hic quas.
        </p>
      </div> -->

      <div>
        <iframe
          style="border: 0; width: 100%; height: 270px"
          src="https://maps.google.com/maps?hl=en&amp;q=71-75%2C%20Shelton%20Street%20%20London%2C%20Greater%20London+(Strategic%20Explorations%20Network)&amp;ie=UTF8&amp;t=&amp;z=13&amp;iwloc=B&amp;output=embed"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="info">
            <div class="address">
              <i class="icofont-google-map"></i>
              <h4>{{ $t("contact.location") }}:</h4>
              <p>71-75, Shelton Street</p>
              <p>London, Greater London ,WC2H 9JQ</p>
            </div>

            <div class="email">
              <i class="icofont-envelope"></i>
              <h4>{{ $t("contact.email") }}:</h4>
              <p>info@strexp.net</p>
            </div>

            <div class="phone">
              <i class="icofont-phone"></i>
              <h4>{{ $t("contact.phone") }}:</h4>
              <p>[REDACTED]</p>
            </div>
          </div>
        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">
          <form
            action="forms/contact"
            method="post"
            role="form"
            class="php-email-form"
          >
            <div class="form-row">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  :placeholder="$t('contact.your-name')"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  :placeholder="$t('contact.your-email')"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                :placeholder="$t('contact.subject')"
                data-rule="minlen:4"
                data-msg="Please enter at least 8 chars of subject"
              />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                data-rule="required"
                data-msg="Please write something for us"
                :placeholder="$t('contact.message')"
              ></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">{{ $t("contact.send") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</template>

<script>
export default {};
</script>

<style>
#contact {
  padding-top: 40px;
}
</style>
