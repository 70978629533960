<template>
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients section-bg">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-1.png" class="img-fluid" alt />
        </div>

        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-2.png" class="img-fluid" alt />
        </div>

        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-3.png" class="img-fluid" alt />
        </div>

        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-4.png" class="img-fluid" alt />
        </div>

        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-5.png" class="img-fluid" alt />
        </div>

        <div
          class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/clients/client-6.png" class="img-fluid" alt />
        </div>
      </div>
    </div>
  </section>
  <!-- End Clients Section -->
</template>

<script>
export default {};
</script>

<style></style>
