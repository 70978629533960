<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div
      class="container position-relative"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 text-center">
          <h1>{{ $t("hero.title") }}</h1>
          <h2>{{ $t("hero.descr") }}</h2>
        </div>
      </div>
      <div class="text-center">
        <a href="#about" class="btn-get-started scrollto">{{
          $t("hero.about")
        }}</a>
      </div>

      <div class="row icon-boxes">
        <div
          class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div class="icon-box">
            <div class="icon">
              <i class="ri-stack-line"></i>
            </div>
            <h4 class="title">
              <a href>{{ $t("hero.c1title") }}</a>
            </h4>
            <p class="description">
              {{ $t("hero.c1descr") }}
            </p>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div class="icon-box">
            <div class="icon">
              <i class="ri-palette-line"></i>
            </div>
            <h4 class="title">
              <a href>{{ $t("hero.c2title") }}</a>
            </h4>
            <p class="description">
              {{ $t("hero.c2descr") }}
            </p>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="zoom-in"
          data-aos-delay="400"
        >
          <div class="icon-box">
            <div class="icon">
              <i class="ri-command-line"></i>
            </div>
            <h4 class="title">
              <a href>{{ $t("hero.c3title") }}</a>
            </h4>
            <p class="description">
              {{ $t("hero.c3descr") }}
            </p>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="zoom-in"
          data-aos-delay="500"
        >
          <div class="icon-box">
            <div class="icon">
              <i class="ri-fingerprint-line"></i>
            </div>
            <h4 class="title">
              <a href>{{ $t("hero.c4title") }}</a>
            </h4>
            <p class="description">
              {{ $t("hero.c4descr") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->
</template>

<script>
export default {};
</script>

<style></style>
