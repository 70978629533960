<template>
  <!-- ======= Cta Section ======= -->
  <section id="cta" class="cta">
    <div class="container" data-aos="zoom-in">
      <div class="text-center">
        <h3>{{ $t("cta.title") }}</h3>
        <p>
          {{ $t("cta.descr") }}
        </p>
        <a class="cta-btn" href="#">{{ $t("cta.but") }}</a>
      </div>
    </div>
  </section>
  <!-- End Cta Section -->
</template>

<script>
export default {};
</script>

<style></style>
